<template>
  <div class="mobileMenu">
    <router-link to="/" class="logo" tag="div">
      <img src="@/assets/logo2.svg" alt="">
    </router-link>
    <div class="iconMenu" @click="visualMenu = true">
      <div class="burgerLine"></div>
    </div>
    <div class="feedback">
      <router-link to="feedback">Связаться с нами</router-link>
    </div>
    <transition name="transform" mode="out-in">
      <div class="navMenu" v-if="visualMenu">
        <ul>
          <li v-for="item in myMenu" @click="visualMenu = false">
            <router-link :to="item.path">{{item.title}}</router-link>
          </li>
        </ul>
        <div class="contacts">
          <a href="tel:+79533493380" class="phoneNumber">+7 (953) 349-33-80</a>
          <a href="mailto:izvarindima@gmail.com">Izvarindima@gmail.com</a>
          <div class="linkContacts">
            <a target="_blank" href="https://wa.me/79533493380/?text=hello">
              <svg-ws/>
            </a>
            <a href="tg://resolve?domain=Izvarin_law">
              <svg-tg/>
            </a>
            <a target="_blank" href="https://www.instagram.com/izvarin_law/">
              <inst/>
            </a>
          </div>
        </div>
        <div class="close" @click.stop="visualMenu = false"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import SvgTg from "@/components/svgTg";
import Inst from "@/components/inst";
import SvgWs from "@/components/svgWs";
export default {
  name: "mainMenuMobile",
  components: {SvgWs, Inst, SvgTg},
  data() {
    return {
      visualMenu: false,
      myMenu:[
        {path: '/', title: 'Главная'},
        {path: '/service', title: 'Услуги'},
        {path: '/case', title: 'Кейсы'},
        {path: '/news', title: 'Новости'},
        {path: '/contacts', title: 'Контакты'},
      ],
    }
  },
}
</script>

<style scoped lang="sass">
  .contacts
    display: flex
    flex-direction: column
    padding-left: 20px
    a
      color: #ffffff
      font-size: 16px
      text-decoration: none
      margin-bottom: 15px
    .linkContacts
      padding-bottom: 20px
      img
        margin-right: 15px
  .transform-enter-active
    animation: slideIn 0.2s linear

  .transform-leave-active
    animation: slideOut 0.2s linear

  @keyframes slideIn
    0%
      transform: translateX(100%)
    50%
      transform: translateX(50%)
    100%
      transform: translateX(0)

  @keyframes slideOut
    0%
      transform: translateX(0)
    50%
      transform: translateX(50%)
    100%
      transform: translateX(100%)
  .navMenu
    position: fixed
    z-index: 12
    display: flex
    flex-direction: column
    align-items: flex-start
    right: 0
    top: 0
    width: 80%
    height: 100%
    background-color: rgba(0,0,0,.9)
    padding-top: 30px
    padding-left: 20px
    ul
      flex-grow: 2
      list-style-type: none
      width: 100%
      li
        font-size: 18px
        width: 100%
        position: relative
        font-weight: 400
        cursor: pointer
        a
          padding: 10px 0
          color: #fff
          text-decoration: none
          display: block
      .router-link-exact-active
        font-size: 24px
        font-weight: 600
        position: relative
        &:before
          content: ''
          display: block
          position: absolute
          left: -25px
          top: 12px
          width: 2px
          height: 30px
          background-color: #fff
  .close
    position: absolute
    right: 22px
    top: 12px
    width: 30px
    height: 30px
    cursor: pointer
    &:before
      position: absolute
      width: 25px
      height: 2px
      transform: rotate(45deg)
      content: ''
      background-color: #fff
      top: 10px
    &:after
      position: absolute
      width: 25px
      height: 2px
      top: 10px
      transform: rotate(-45deg)
      content: ''
      background-color: #fff
  .mobileMenu
    z-index: 11
    position: fixed
    height: 40px
    top: 0
    left: 0
    background-color: rgba(10,10,10, .98)
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
  .logo
    display: flex
    padding-left: 30px
    img
      width: 150px
      margin-right: 10px
    .text
      font-size: 12px
      font-weight: 500
  .burgerLine
    width: 21px
    height: 3px
    background-color: #FFFFFF
    position: relative
    margin-right: 30px
    &:before,&:after
      content: ''
      width: 21px
      height: 3px
      background-color: #FFFFFF
      position: absolute
      right: 0
      top: 7px
    &:after
      top: -7px
  .feedback
    position: fixed
    bottom: 0
    background-color: #000
    width: 100%
    left: 0
    font-weight: 500
    cursor: pointer
    padding: 10px 0 10px 30px
    font-size: 16px
    a
      color: #DB6500
      text-decoration: none
      font-weight: 300
    &:before
      display: block
      position: absolute
      left: 0
      content: ''
      background-color: #fff
      height: 1px
      width: 20px
      top: 20px
      opacity: .8
</style>