<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 0.34375C4.92339 0.34375 0 5.11328 0 11C0 16.8867 4.92339 21.6562 11 21.6562C17.0766 21.6562 22 16.8867 22 11C22 5.11328 17.0766 0.34375 11 0.34375ZM16.4024 7.64414L14.5972 15.8855C14.4641 16.4699 14.1048 16.6117 13.6036 16.3367L10.8536 14.373L9.52742 15.6105C9.38105 15.7523 9.25686 15.8727 8.97298 15.8727L9.16815 13.1613L14.2645 8.70117C14.4863 8.51211 14.2157 8.40469 13.923 8.59375L7.6246 12.4352L4.91008 11.6145C4.32016 11.434 4.30685 11.043 5.03427 10.768L15.6395 6.80625C16.1319 6.63437 16.5621 6.92227 16.4024 7.64414Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "svgTg"
}
</script>

<style scoped lang="sass">
  svg
    margin-right: 10px
</style>