<template>
  <svg width="656" height="805" viewBox="0 0 656 805" fill="none" xmlns="http://www.w3.org/2000/svg" :class="feedback?'feedbackSvg':''">
    <g opacity="">
      <path opacity=".6" d="M327.988 629.058C493.143 629.058 627.027 495.181 627.027 330.035C627.027 164.889 493.143 31.0113 327.988 31.0113C162.833 31.0113 28.9486 164.889 28.9486 330.035C28.9486 495.181 162.833 629.058 327.988 629.058Z" fill="#090909"/>
      <path opacity=".6"  d="M0 796.716L656 768.705C656 768.705 549.327 820.622 339.39 832.576C97.8155 846.353 0 796.716 0 796.716Z" fill="#090909"/>
      <path class="rightBlock" d="M555.856 192.38L535.357 257.427H537.181L557.32 196.269L578.012 257.427H580.388L558.233 192.38H555.856Z" fill="#346F5B"/>
      <path class="rightBlock" d="M529.404 257.763C529.404 257.763 530.868 284.166 557.872 282.246C584.877 280.326 586.797 257.811 586.797 257.811L529.404 257.763Z" fill="#224539"/>
      <path class="leftBlock" d="M451.488 191.492L430.964 256.539H432.813L452.952 195.405L473.643 256.539H476.02L453.864 191.492H451.488Z" fill="#346F5B"/>
      <path class="leftBlock" d="M425.011 256.827C425.011 256.827 426.476 283.23 453.48 281.31C480.484 279.389 482.476 256.827 482.476 256.827H425.011Z" fill="#224539"/>
      <path class="rightBlock" d="M527.556 254.859H589.822L589.173 260.955H527.748L527.556 254.859Z" fill="#6AB79F"/>
      <path class="leftBlock" d="M423.163 253.635H485.453L484.805 259.707H423.379L423.163 253.635Z" fill="#6AB79F"/>
      <path class="line" d="M444.983 192.356C444.989 192.889 445.203 193.398 445.58 193.775C445.957 194.152 446.466 194.366 446.999 194.372L559.673 193.916C560.189 193.923 560.688 193.729 561.064 193.376C561.44 193.022 561.664 192.536 561.689 192.02V190.868C561.692 190.6 561.642 190.335 561.54 190.087C561.438 189.839 561.288 189.615 561.097 189.427C560.907 189.238 560.68 189.09 560.432 188.992C560.183 188.893 559.916 188.845 559.649 188.852L446.999 190.46C446.483 190.453 445.984 190.647 445.608 191.001C445.231 191.355 445.007 191.841 444.983 192.356Z" fill="#6AB79F"/>
      <path d="M244.791 66.439C263.138 66.439 278.012 51.5661 278.012 33.2195C278.012 14.8729 263.138 0 244.791 0C226.443 0 211.57 14.8729 211.57 33.2195C211.57 51.5661 226.443 66.439 244.791 66.439Z" fill="#346F5B"/>
      <path d="M288.67 52.1575C307.017 52.1575 321.891 43.6787 321.891 33.2195C321.891 22.7603 307.017 14.2815 288.67 14.2815C270.322 14.2815 255.448 22.7603 255.448 33.2195C255.448 43.6787 270.322 52.1575 288.67 52.1575Z" fill="#346F5B"/>
      <path d="M258.425 103.091C275.129 103.091 288.67 89.5505 288.67 72.8477C288.67 56.1448 275.129 42.6045 258.425 42.6045C241.721 42.6045 228.18 56.1448 228.18 72.8477C228.18 89.5505 241.721 103.091 258.425 103.091Z" fill="#346F5B"/>
      <path d="M117.835 316.905L112.53 374.007H132.309V316.905H117.835Z" fill="#346F5B"/>
      <path d="M255.593 144.711C255.593 144.711 216.826 164.657 202.784 180.595V315.177L150.888 335.123L125.972 320.722L96.0632 335.123L109.025 363.038H139.942L151.944 350.077L225.372 355.069L317.426 214.511L382.237 181.603L459.049 182.587L475.995 137.751C475.995 137.751 463.993 92.8898 475.995 65.959H504.8L516.802 46.0128L498.847 37.0599L463.969 49.0612L432.068 148.816L302.448 137.871L296.471 113.868L317.402 117.852L323.379 94.93L336.317 96.2501L328.372 64.6388V49.0132L277.532 45.0287L265.53 69.0313C265.53 69.0313 251.44 60.1263 246.591 71.0235C239.054 87.9693 258.593 90.9456 258.593 90.9456L255.593 144.711Z" fill="#6AB79F"/>
      <path d="M330.7 134.75L336.341 235.441L310.417 347.077L237.638 400.914L202.76 367.023L189.798 437.806L227.676 482.667L341.19 367.023L363.274 137.727H354.632L345.655 134.75H330.7Z" fill="#346F5B"/>
      <path d="M346.039 320.818L341.19 367.023L227.676 482.667L194.263 443.111L172.179 615.929L202.808 673.079L194.119 730.229L208.161 796.692L365.266 815.294L400.071 795.372L438.838 794.028L412.697 724.924L425.707 714.267L386.413 657.117L368.938 536.168L379.116 408.571L346.039 320.818Z" fill="#191919"/>
      <path d="M202.808 673.079L272.875 647.828L364.954 544.137L318.17 681.048L194.119 730.229L202.808 673.079Z" fill="#346F5B"/>
      <path d="M400.071 795.372L345.967 719.596L365.266 815.294L400.071 795.372Z" fill="#346F5B"/>
      <path d="M332.525 167.154L292.654 191.132L242.63 182.611L218.075 229.128L233.317 310.209L202.808 367.071L237.71 400.938L310.465 347.053L336.317 235.393L332.525 167.154Z" fill="#191919"/>
      <path d="M99.8799 381.976C99.8799 381.976 93.0868 757.472 124.268 799.284C124.268 799.284 152.232 712.203 148.056 381.904L99.8799 381.976Z" fill="#346F5B"/>
      <path d="M66.1785 371.343L69.659 389.297H178.324L181.829 371.343H66.1785Z" fill="#191919"/>
      <path d="M118.339 310.209L115.698 344.772C118.135 346.471 121.034 347.382 124.004 347.382C126.974 347.382 129.873 346.471 132.309 344.772V310.209H118.339Z" fill="#346F5B"/>
      <path d="M124.004 396.042H142.943C142.943 396.042 137.782 720.916 122.419 780.73L124.004 396.042Z" fill="#478A73"/>
      <path opacity="0.2" d="M103.072 524.527L145.439 504.101V395.946H104.393L103.072 524.527Z" fill="#7DF2CA"/>
      <path opacity="0.2" d="M103.048 547.474L145.439 531.008C145.439 531.008 144.431 557.939 144.191 565.404L103.048 578.125C103.048 578.125 103.216 550.954 103.048 547.474Z" fill="#7DF2CA"/>
      <path d="M220.691 580.525H255.593L321.387 531.008L258.425 604.624L220.691 580.525Z" fill="#346F5B"/>
      <path d="M356.288 618.233V695.017L412.697 724.924L425.707 714.267L373.019 686.04L356.288 618.233Z" fill="#346F5B"/>
      <path d="M335.189 403.219L277.676 471.362L309.313 474.362L335.189 403.219Z" fill="#346F5B"/>
      <path d="M229.02 157.769L241.838 187.844L249.112 184.603L241.358 148.672L235.717 151.456L234.157 154.696L229.02 157.769Z" fill="#191919"/>
      <path d="M234.157 154.696L242.366 175.483L235.717 151.456L234.157 154.696Z" fill="#346F5B"/>
      <path d="M251.56 188.516C252.912 187.177 253.835 185.466 254.213 183.601C254.591 181.736 254.406 179.801 253.682 178.042C252.958 176.282 251.727 174.777 250.147 173.718C248.566 172.659 246.706 172.094 244.803 172.094C242.9 172.094 241.04 172.659 239.459 173.718C237.878 174.777 236.647 176.282 235.923 178.042C235.199 179.801 235.015 181.736 235.392 183.601C235.77 185.466 236.694 187.177 238.046 188.516C239.844 190.297 242.272 191.296 244.803 191.296C247.333 191.296 249.762 190.297 251.56 188.516Z" fill="#346F5B"/>
      <path d="M251.56 181.771C251.565 180.429 251.17 179.116 250.427 177.999C249.684 176.882 248.626 176.011 247.387 175.496C246.147 174.982 244.783 174.847 243.467 175.109C242.151 175.372 240.943 176.019 239.996 176.97C239.049 177.92 238.405 179.131 238.148 180.447C237.89 181.764 238.03 183.128 238.549 184.365C239.068 185.603 239.943 186.658 241.063 187.397C242.183 188.136 243.497 188.525 244.839 188.516C246.622 188.503 248.327 187.788 249.586 186.525C250.844 185.262 251.554 183.554 251.56 181.771Z" fill="#191919"/>
      <path d="M235.237 246.89L266.538 256.635L305.52 241.921L264.57 270.316L235.237 246.89Z" fill="#346F5B"/>
      <path d="M233.317 310.209H260.585L318.41 284.43L281.013 320.818L238.046 328.811L209.817 354.013L233.317 310.209Z" fill="#346F5B"/>
      <path d="M336.317 235.393L318.41 241.922L330.364 261.7L336.317 235.393Z" fill="#346F5B"/>
      <path d="M345.655 134.75L346.639 208.174L354.632 137.727L345.655 134.75Z" fill="#396D5B"/>
      <path d="M350.143 223.968L327.364 343.596L296.471 371.343L333.845 356.558L350.143 223.968Z" fill="#396D5B"/>
      <path d="M198.679 389.297L233.173 414.884L280.508 389.297L235.237 447.767L212.722 435.31L189.798 437.806L198.679 389.297Z" fill="#396D5B"/>
      <path d="M255.592 435.31L297.455 389.297L286.509 415.868L255.592 435.31Z" fill="#396D5B"/>
      <path d="M216.514 469.441L246.111 443.279L227.676 482.667L216.514 469.441Z" fill="#396D5B"/>
      <path d="M123.86 315.681L122.419 344.484C122.419 344.484 127.484 345.972 130.485 342.972V315.321C128.277 314.953 126.015 315.076 123.86 315.681Z" fill="#396D5B"/>
      <path @click="funClick()" d="M138.022 302.888C138.041 300.106 137.233 297.381 135.7 295.058C134.168 292.736 131.98 290.922 129.415 289.845C126.849 288.768 124.022 288.478 121.291 289.01C118.56 289.543 116.049 290.875 114.076 292.838C112.104 294.8 110.759 297.304 110.212 300.032C109.665 302.76 109.941 305.589 111.004 308.16C112.068 310.731 113.871 312.928 116.186 314.472C118.5 316.016 121.221 316.838 124.004 316.833C127.709 316.833 131.264 315.366 133.891 312.753C136.518 310.14 138.003 306.593 138.022 302.888Z" fill="#346F5B"/>
      <path d="M124.412 297.631C124.407 296.769 124.147 295.928 123.665 295.213C123.184 294.499 122.501 293.943 121.704 293.615C120.906 293.287 120.03 293.203 119.185 293.372C118.34 293.541 117.563 293.957 116.954 294.566C116.344 295.176 115.929 295.952 115.76 296.797C115.59 297.642 115.675 298.519 116.002 299.316C116.33 300.113 116.886 300.796 117.601 301.278C118.316 301.759 119.157 302.019 120.019 302.024C120.597 302.027 121.169 301.916 121.704 301.696C122.238 301.476 122.724 301.153 123.132 300.744C123.541 300.336 123.864 299.85 124.084 299.316C124.303 298.782 124.415 298.209 124.412 297.631Z" fill="#6AB79F"/>
      <path d="M242.366 23.4505C247.802 23.4505 252.208 19.0445 252.208 13.6094C252.208 8.17438 247.802 3.7684 242.366 3.7684C236.931 3.7684 232.525 8.17438 232.525 13.6094C232.525 19.0445 236.931 23.4505 242.366 23.4505Z" fill="#458670"/>
      <path d="M69.491 374.007L72.4915 386.633H160.561L156.553 374.007H69.491Z" fill="#346F5B"/>
      <path d="M266.562 148.672L288.67 151.456L303.12 144.711L288.67 162.065L266.562 148.672Z" fill="#6BCEAD"/>
      <path d="M279.524 66.463L332.693 74.7919L336.317 85.233L321.891 87.8973L277.532 74.7919L279.524 66.463Z" fill="#396D5B"/>
      <path class="fun" d="M284.757 70.6874L332.237 77.1681L328.756 81.4166L284.757 70.6874Z" fill="#224539"/>
      <path d="M334.894 58.8539C342.159 51.5894 342.159 39.8113 334.894 32.5467C327.629 25.2822 315.85 25.2822 308.585 32.5467C301.32 39.8113 301.32 51.5894 308.585 58.8539C315.85 66.1184 327.629 66.1184 334.894 58.8539Z" fill="#346F5B"/>
      <path d="M296.135 63.4627C304.487 63.4627 311.257 56.6925 311.257 48.3411C311.257 39.9897 304.487 33.2195 296.135 33.2195C287.783 33.2195 281.013 39.9897 281.013 48.3411C281.013 56.6925 287.783 63.4627 296.135 63.4627Z" fill="#346F5B"/>
      <path d="M277.532 74.7919C285.884 74.7919 292.654 68.0217 292.654 59.6703C292.654 51.3189 285.884 44.5487 277.532 44.5487C269.18 44.5487 262.41 51.3189 262.41 59.6703C262.41 68.0217 269.18 74.7919 277.532 74.7919Z" fill="#346F5B"/>
      <path d="M502.04 52.1575C501.809 51.6919 501.454 51.2999 501.013 51.0258C500.571 50.7517 500.062 50.6065 499.543 50.6065C499.024 50.6065 498.515 50.7517 498.074 51.0258C497.632 51.2999 497.277 51.6919 497.047 52.1575L498.631 73.3277C498.698 73.8891 498.961 74.4089 499.374 74.7953C499.787 75.1817 500.323 75.41 500.887 75.4399C501.524 75.4399 502.135 75.1871 502.585 74.7369C503.035 74.2868 503.288 73.6763 503.288 73.0397L502.04 52.1575Z" fill="#224539"/>
      <path d="M501.439 93.3698C501.151 93.3696 500.865 93.4278 500.599 93.5411C500.333 93.6544 500.094 93.8204 499.894 94.0291C499.694 94.2377 499.539 94.4847 499.437 94.7551C499.336 95.0254 499.29 95.3136 499.303 95.6021L503.168 190.58H505.832L503.576 95.4581C503.563 94.8999 503.333 94.3688 502.933 93.9785C502.534 93.5882 501.998 93.3697 501.439 93.3698Z" fill="#224539"/>
      <path d="M510.177 198.069C511.529 196.73 512.452 195.019 512.83 193.154C513.208 191.289 513.023 189.354 512.299 187.595C511.575 185.835 510.344 184.33 508.764 183.271C507.183 182.212 505.323 181.647 503.42 181.647C501.517 181.647 499.657 182.212 498.076 183.271C496.495 184.33 495.264 185.835 494.54 187.595C493.816 189.354 493.632 191.289 494.009 193.154C494.387 195.019 495.311 196.73 496.663 198.069C498.461 199.85 500.889 200.849 503.42 200.849C505.95 200.849 508.379 199.85 510.177 198.069Z" fill="#346F5B"/>
      <path d="M510.177 191.324C510.182 189.983 509.788 188.671 509.046 187.555C508.304 186.438 507.247 185.567 506.009 185.051C504.771 184.536 503.409 184.4 502.093 184.66C500.778 184.921 499.57 185.566 498.621 186.514C497.673 187.462 497.028 188.67 496.768 189.986C496.507 191.301 496.643 192.664 497.159 193.902C497.674 195.139 498.545 196.196 499.662 196.938C500.779 197.68 502.091 198.074 503.432 198.069C504.318 198.069 505.195 197.894 506.013 197.555C506.831 197.217 507.575 196.72 508.201 196.093C508.828 195.467 509.325 194.724 509.663 193.905C510.002 193.087 510.177 192.21 510.177 191.324Z" fill="#191919"/>
      <path d="M202.808 315.177L217.138 318.97L222.443 325.498L221.243 315.105L202.808 315.177Z" fill="#346F5B"/>
      <path d="M229.908 60.3904C230.517 48.8464 235.42 37.9471 243.654 29.8332C251.889 21.7192 262.859 16.9769 274.411 16.5377C267.906 13.6486 260.664 12.8489 253.684 14.249C246.705 15.6491 240.332 19.18 235.444 24.3548C230.556 29.5295 227.394 36.0933 226.394 43.1408C225.394 50.1884 226.605 57.3726 229.86 63.7027C229.836 62.6226 229.836 61.5185 229.908 60.3904Z" fill="#224539"/>
      <path d="M331.252 31.9954C330.728 30.8625 329.784 29.9782 328.619 29.5301C327.453 29.082 326.16 29.1053 325.011 29.5951C324.014 30.3305 323.339 31.4232 323.129 32.6445C322.919 33.8659 323.189 35.1212 323.883 36.1478C324.407 37.2867 325.356 38.1754 326.527 38.6239C327.698 39.0724 328.997 39.0452 330.148 38.5481C331.142 37.8091 331.812 36.7147 332.017 35.4936C332.223 34.2725 331.949 33.0192 331.252 31.9954Z" fill="#458670"/>
      <path d="M336.797 41.4043C336.498 41.0632 336.108 40.8139 335.673 40.6852C335.238 40.5565 334.776 40.5538 334.339 40.6773C333.902 40.8009 333.51 41.0457 333.207 41.3833C332.903 41.7209 332.702 42.1375 332.626 42.5848C332.55 43.0321 332.603 43.4918 332.777 43.9106C332.952 44.3293 333.241 44.6901 333.613 44.9509C333.984 45.2117 334.422 45.362 334.875 45.3843C335.328 45.4065 335.778 45.2999 336.173 45.0767C336.736 44.6675 337.117 44.0541 337.234 43.3677C337.35 42.6814 337.194 41.9767 336.797 41.4043Z" fill="#458670"/>
      <path d="M273.979 57.126C283.581 49.4212 286.341 36.6519 279.932 28.635C273.523 20.6182 260.513 20.3542 250.84 28.083C241.166 35.8118 238.478 48.5331 244.839 56.574C251.2 64.6148 264.282 64.8068 273.979 57.126Z" fill="#458670"/>
      <path d="M191.718 462.985L228.036 537.489L258.425 524.527L220.691 568.044L194.119 515.526V599.967L187.782 618.233L202.808 673.079L172.179 615.929L191.718 462.985Z" fill="#396D5B"/>
      <path d="M368.939 536.169L332.237 493.636L350.144 380.32L354.632 494.956L368.939 536.169Z" fill="#396D5B"/>
      <path d="M208.161 796.692L258.425 747.535L328.276 730.229L341.19 812.462L208.161 796.692Z" fill="#396D5B"/>
      <path d="M438.838 794.028L386.197 730.229V751.495L418.77 794.028H438.838Z" fill="#396D5B"/>
      <path d="M265.722 716.932L332.237 689.689L316.754 716.932H265.722Z" fill="#346F5B"/>
      <path d="M511.281 74.8159C509.32 72.8558 506.823 71.5211 504.103 70.9805C501.384 70.44 498.566 70.7177 496.004 71.7788C493.443 72.8398 491.254 74.6365 489.714 76.9416C488.174 79.2467 487.352 81.9567 487.352 84.7289C487.352 87.5012 488.174 90.2112 489.714 92.5163C491.254 94.8213 493.443 96.618 496.004 97.6791C498.566 98.7401 501.384 99.0179 504.103 98.4773C506.823 97.9367 509.32 96.602 511.281 94.642C512.589 93.3435 513.627 91.7991 514.335 90.0978C515.043 88.3965 515.408 86.5718 515.408 84.7289C515.408 82.886 515.043 81.0614 514.335 79.3601C513.627 77.6587 512.589 76.1143 511.281 74.8159ZM509.337 92.6978C507.76 94.2778 505.75 95.3545 503.561 95.7916C501.371 96.2288 499.102 96.0068 497.039 95.1536C494.976 94.3004 493.213 92.8545 491.972 90.9988C490.732 89.1431 490.069 86.9611 490.069 84.7289C490.069 82.4967 490.732 80.3147 491.972 78.459C493.213 76.6034 494.976 75.1574 497.039 74.3043C499.102 73.4511 501.371 73.229 503.561 73.6662C505.75 74.1034 507.76 75.1801 509.337 76.7601C510.392 77.7994 511.232 79.0372 511.807 80.4022C512.383 81.7673 512.682 83.2326 512.689 84.7139C512.696 86.1953 512.409 87.6633 511.846 89.0335C511.283 90.4037 510.455 91.649 509.409 92.6978H509.337Z" fill="#346F5B"/>
    </g>
  </svg>

</template>

<script>
export default {
  name: "svgHomeAnimate",
  props: ['feedback'],
  data() {
    return {
      line: null,
      left: null,
      right: null
    }
  },
  methods: {

    funClick(){
      let elem = document.querySelector('.fun');
      elem.style.fill = '#ff0000';
      setTimeout(()=>{
        elem.style.fill = 'rgb(34, 69, 57)';
      },4000)
    }
  },
  mounted() {
  },
  created() {

  }
}
</script>

<style scoped lang="sass">
  =r($width)
    @media only screen and (max-width: $width+ "px")
      @content
  .feedbackSvg
    position: absolute
    z-index: 10
    transform: none
    left: 66px
    top: 40px
    width: 500px
    +r(1500)
      left: auto
      right: -100px
      top: 50px
    +r(1400)
      width: 400px
      top: 75px
    +r(998)
      top: 150px
      right: -80px

  svg
    transform: translate(-70px, 100px)
    +r(5000)
      height: 1200px
      width: 1200px
    +r(2300)
      width: 700px
      height: 850px
    +r(1800)
      position: absolute
      width: 700px
      z-index: 1
      left: 600px
      top: 100px
      transform: none
    +r(1500)
      width: 700px
      left: 520px
    +r(1400)
      width: 500px
      left: 520px
      height: 600px
    +r(998)
      transform: scale(-1, 1) translateX(-140px)
      right: 0
      left: 220px
      top: 40px
      width: 500px
      height: 600px
    +r(550)
      left: 20px
      width: 350px
      height: 500px
      opacity: .2

  .leftBlock
    transform-origin: 76.5% 23.5%
    animation: balance 9s linear infinite reverse
  .rightBlock
    transform-origin: 76.5% 23.4%
    animation: balanceRight 9s linear infinite
  .line
    transform-origin: 77.5% 23.5%
    animation: rotation 9s linear infinite reverse
  @keyframes balanceRight
    0%
      transform: translate(-5px,0)
    13%
      transform: translate(-5px,-9px)
    25%
      transform: translate(-5px,-18px)
    37%
      transform: translate(-5px,-9px)
    50%
      transform: translate(-5px,0)
    63%
      transform: translate(-5px,9px)
    75%
      transform: translate(-5px,18px)
    87%
      transform: translate(-5px,9px)
    100%
      transform: translate(-5px,0)
  @keyframes balance
    0%
      transform: translate(0,0)
    13%
      transform: translate(0,-9px)
    25%
      transform: translate(0,-18px)
    37%
      transform: translate(0,-9px)
    50%
      transform: translate(0,0)
    63%
      transform: translate(0,10px)
    75%
      transform: translate(0,21px)
    87%
      transform: translate(0,10px)
    100%
      transform: translate(0,0)
  @keyframes rotation
    0%
      transform: rotate(0deg)
    13%
      transform: rotate(11deg)
    25%
      transform: rotate(22deg)
    37%
      transform: rotate(11deg)
    50%
      transform: rotate(0deg)
    63%
      transform: rotate(-11deg)
    75%
      transform: rotate(-22deg)
    87%
      transform: rotate(-11deg)
    100%
      transform: rotate(0deg)
</style>