<template>
  <div class="container">
    <router-link to="/" class="logo" tag="div">
      <img src="@/assets/logo2.svg" alt="">
    </router-link>

    <div class="mainMenu" v-if="$route.name !== 'feedback'">
      <ul>
        <li v-for="item in myMenu">
          <router-link :to="item.path">{{item.title}}</router-link>
        </li>
      </ul>
    </div>
    <div class="contMenu" v-else>
      <svg-home-animate  :feedback="true"/>
    </div>

    <div class="feedback">
      <router-link to="feedback">Связаться с нами</router-link>
    </div>
  </div>
</template>

<script>
import SvgHomeAnimate from "@/components/svgHomeAnimate";
export default {
  name: "mainMenu",
  components: {SvgHomeAnimate},
  data() {
    return {
      hiddenMenu: false,
      myMenu:[
        {path: '/', title: 'Главная'},
        {path: '/service', title: 'Услуги'},
        {path: '/case', title: 'Кейсы'},
        {path: '/news', title: 'Новости'},
        {path: '/contacts', title: 'Контакты'},
      ],
    }
  },
}
</script>

<style scoped lang="sass">
  =r($width)
    @media only screen and (max-width: $width+ "px")
      @content
  .container
    height: 100vh
    width: 450px
    z-index: 3
    background-color: #090909
    display: flex
    flex-direction: column
    position: fixed
    left: 0
    top: 0
    +r(1500)
      width: 250px
  .mainMenu,.contMenu
    flex-grow: 2
  .logo
    cursor: pointer
    display: flex
    height: 100px
    padding: 20px 10px 20px 60px
    align-items: center
    position: relative
    +r(1500)
      padding: 10px
      img
        width: calc(100% - 20px)
    &:before
      display: block
      position: absolute
      left: 0
      content: ''
      background-color: #fff
      top: 49px
      height: 2px
      opacity: .8
      width: 30px
      +r(1500)
        display: none
  .text
    margin: 0
    padding: 0 15px
    color: #fff
    font-size: 18px
    font-weight: 500
    +r(1500)
      font-size: 14px
      padding: 0 0 0 15px
  .mainMenu
    padding: 80px 20px 20px 100px
    color: #fff
    +r(1500)
      padding: 40px 10px 10px 30px
    ul
      list-style-type: none
      li
        font-size: 20px
        width: min-content
        position: relative
        font-weight: 400
        cursor: pointer
        +r(1500)
          font-size: 18px
        a
          padding: 20px 0
          color: #fff
          text-decoration: none
          display: block
          +r(1500)
            padding: 10px 0
        &:hover
          &:after
            display: block
            position: absolute
            left: 0
            content: ''
            background-color: #DB6500
            bottom: 10px
            height: 3px
            width: 100%
            +r(1500)
              display: none
      .router-link-exact-active
        font-size: 34px
        font-weight: 600
        position: relative
        +r(1500)
          font-size: 24px
        &:hover
          border-bottom: none
          &:after
            display: none
        &:before
          content: ''
          display: block
          position: absolute
          left: -25px
          top: 15px
          width: 3px
          height: 50px
          background-color: #fff
          +r(1500)
            height: 30px
            top: 12px
  .feedback
    position: relative
    font-weight: 500
    font-size: 22px
    cursor: pointer
    padding: 20px 10px 30px 60px
    z-index: 10
    +r(1500)
      padding: 20px 10px 30px 40px
      font-size: 16px
    a
      color: #DB6500
      text-decoration: none
    &:before
      display: block
      position: absolute
      left: 0
      content: ''
      background-color: #fff
      opacity: .8
      top: 33px
      height: 2px
      width: 30px
      +r(1500)
        width: 20px
        top: 29px
        //display: none
</style>