<template>
  <div class="home">
    <div class="homeText">
      <div class="title">
        <p>
        Юридическая помощь <br>
        для <span>физических</span><br>
        и <span>юридических</span> лиц
        </p>
      </div>
      <div class="description">
        <p>Предоставляем профессиональные правовые <br> консультации, оказываем юридические услуги <br>
          гражданам <span class="test"><br></span> и организациям различных<br> организационно-правовых форм.
        </p>
      </div>
    </div>
    <svg-home-animate/>
  </div>
</template>

<script>
import SvgHomeAnimate from "@/components/svgHomeAnimate";
export default {
  name: "home",
  components: {SvgHomeAnimate},
  created() {
    document.title = 'IzvarinLaw';
  }
}
</script>

<style scoped lang="sass">
  =r($width)
    @media only screen and (max-width: $width+ "px")
      @content
  .home
    width: 100%
    display: flex
    position: relative
    overflow-y: hidden
    justify-content: center
    overflow-x: hidden
    +r(1800)
      overflow-x: hidden
    +r(998)
      overflow-x: hidden

  .homeText
    width: 700px
    padding: 400px 0 0 60px
    color: #fff
    +r(2300)
      padding: 250px 0 0 60px
    +r(1800)
      position: absolute
      left: 60px
      top: 250px
      padding: 0
      z-index: 2
    +r(998)
      position: absolute
      left: 60px
      top: 180px
      padding: 0
      z-index: 2
      width: 80%
    +r(550)
      left: 30px
      width: 90%
  .title
    font-size: 50px
    margin: 0
    line-height: 1.3
    padding: 0
    font-weight: 500
    +r(1500)
      font-size: 40px
    +r(998)
      font-size: 28px
    +r(750)
      font-size: 26px
      line-height: 1.4
    +r(550)
      font-size: 20px
    p
      margin: 0
      padding: 0
    span
      color: #396D5B
      font-weight: 700
  .description
    font-weight: 500
    font-size: 20px
    padding-top: 22px
    line-height: 1.8
    opacity: .5
    +r(1400)
      line-height: 1.5
    .test
      display: none
      +r(550)
        display: block
    +r(998)
      font-size: 18px
      width: 460px
      br
        display: none
    +r(750)
      font-size: 16px
      padding-top: 0
    +r(550)
      font-size: 14px
      width: 250px
      line-height: 1.6
</style>