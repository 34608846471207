<template>
  <div class="containerFixed">
    <div class="phone">
      <a href="tel:+79533493380" class="phoneNumber">Тел. 8 (953) 349-33-80</a>
    </div>
    <div class="navBar">
      <div class="line"></div>
      <router-link tag="div" class="icon" to="/">
        <img src="../assets/svg/bi_house-door.svg" alt="">
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle r="2.5" transform="matrix(-1 0 0 1 3 3)" stroke="white"/>
        </svg>
      </router-link>
      <router-link tag="div" class="icon" to="/service">
        <img src="../assets/svg/document.svg" alt="">
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle r="2.5" transform="matrix(-1 0 0 1 3 3)" stroke="white"/>
        </svg>
      </router-link>
      <router-link tag="div" class="icon" to="/case">
        <img src="../assets/svg/bi_folder.svg" alt="">
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle r="2.5" transform="matrix(-1 0 0 1 3 3)" stroke="white"/>
        </svg>
      </router-link>
      <router-link tag="div" class="icon" to="/news">
        <img src="../assets/svg/cil_media-play.svg" alt="">
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle r="2.5" transform="matrix(-1 0 0 1 3 3)" stroke="white"/>
        </svg>
      </router-link>
      <router-link tag="div" class="icon" to="/contacts">
        <img src="../assets/svg/clarity_map-marker-line.svg" alt="">
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle r="2.5" transform="matrix(-1 0 0 1 3 3)" stroke="white"/>
        </svg>
      </router-link>
      <router-link tag="div" class="icon feed" to="/feedback">
        <img src="../assets/svg/clarity_mobile-line.svg" alt="">
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle r="2.5" transform="matrix(-1 0 0 1 3 3)" stroke="#DB6500"/>
        </svg>
      </router-link>
    </div>
    <div class="linkMenu">
      <a target="_blank" href="https://wa.me/79533493380/?text=hello">
        <svg-ws/>
      </a>
      <a href="tg://resolve?domain=Izvarin_law">
        <svg-tg/>
      </a>
      <a target="_blank" href="https://www.instagram.com/izvarin_law/">
        <inst/>
      </a>
    </div>
  </div>
</template>

<script>
import SvgTg from "@/components/svgTg";
import Inst from "@/components/inst";
import SvgWs from "@/components/svgWs";
export default {
  name: "contactLine",
  components: {SvgWs, Inst, SvgTg}
}
</script>

<style scoped lang="sass">
  =r($width)
    @media only screen and (max-width: $width+ "px")
      @content
  =rh($height)
    @media only screen and (max-height: $height + "px")
      @content
  .containerFixed
    position: fixed
    right: 0
    top: 0
    z-index: 2
    +r(750)
      display: none
    .phone
      padding: 20px
      position: absolute
      right: 0
      top: 0
      color: #fff
      font-size: 16px
      display: flex
      align-items: center
      font-weight: 500

      &:before
        display: block
        position: absolute
        right: 0
        content: ''
        background-color: #fff
        opacity: .8
        top: 44px
        height: 2px
        width: 30px
      .phoneNumber
        position: absolute
        right: 60px
        top: 35px
        margin: 0
        padding: 0
        color: #fff
        text-decoration: none
        width: max-content
        opacity: .8

  .linkMenu
    position: absolute
    bottom: -100vh
    display: flex
    right: 0
    margin: 0 70px 30px 0
    a
      opacity: .8
      margin: 0
      padding: 0
    &:before
      display: block
      position: absolute
      right: -70px
      content: ''
      background-color: #fff
      top: 10px
      height: 2px
      opacity: .8
      width: 30px
  .insta
    margin-left: 15px
  .navBar
    height: 350px
    width: 1px
    padding: 20px 0
    position: absolute
    top: 50vh
    right: 100px
    display: flex
    flex-direction: column
    justify-content: space-between
    transform: translateY(-50%)
    +rh(800)
      height: 250px
    +r(998)
      display: none
    .line
      position: absolute
      right: 0
      transform: translateX(-50%)
      top: 0
      height: 350px
      opacity: .1
      border-left: 1px dashed #fff
      width: 1px
      +rh(800)
        height: 250px
    .icon
      width: 30px
      height: 30px
      display: flex
      align-items: center
      justify-content: center
      transform: translateX(-50%)
      cursor: pointer
      img
        display: none
    .router-link-exact-active
      width: 30px
      height: 30px
      background-color: #fff
      border-radius: 50%

      img
        display: block
      svg
        display: none
    .router-link-exact-active.feed
      background-color: #DB6500
</style>