<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9973 0H11.0027C17.0679 0 22 4.93488 22 11C22 17.0651 17.0679 22 11.0027 22C8.76562 22 6.69075 21.3345 4.95138 20.1823L0.72325 21.5339L2.09413 17.4474C0.7755 15.6365 0 13.4062 0 11C0 4.9335 4.93213 0 10.9973 0ZM15.2446 17.0857C16.0847 16.9042 17.138 16.2827 17.4034 15.5334C17.6687 14.7826 17.6687 14.1432 17.5917 14.0071C17.5296 13.8991 17.3825 13.8283 17.1631 13.7226C17.1059 13.6951 17.0439 13.6653 16.9771 13.6317C16.654 13.4709 15.0824 12.694 14.7854 12.5909C14.4939 12.4809 14.2161 12.5194 13.9961 12.8301C13.9543 12.8887 13.9125 12.9474 13.871 13.0058C13.6056 13.3791 13.3496 13.7391 13.1367 13.9686C12.9429 14.1749 12.6266 14.201 12.3612 14.091C12.3323 14.0789 12.2992 14.0655 12.262 14.0505C11.8418 13.8808 10.9099 13.5044 9.78174 12.5001C8.83024 11.6531 8.18399 10.5985 7.99699 10.2822C7.81339 9.96498 7.97176 9.77895 8.11813 9.60702C8.12084 9.60384 8.12354 9.60066 8.12624 9.59748C8.22061 9.48072 8.31219 9.3835 8.40422 9.28579C8.47042 9.21551 8.53686 9.14497 8.60474 9.06674C8.61482 9.05512 8.62463 9.04384 8.63418 9.03286C8.77832 8.86713 8.86415 8.76844 8.96087 8.56211C9.07087 8.34899 8.99249 8.12899 8.91412 7.96674C8.86019 7.85311 8.52709 7.04491 8.24113 6.3511C8.11876 6.05419 8.00502 5.77823 7.92549 5.58661C7.71237 5.07649 7.55012 5.05724 7.22699 5.04349C7.21709 5.04299 7.20709 5.04248 7.19699 5.04197C7.09485 5.0368 6.98236 5.03111 6.85849 5.03111C6.43774 5.03111 5.99912 5.15486 5.73374 5.42574C5.72447 5.43521 5.7148 5.44504 5.70477 5.45524C5.36527 5.80052 4.60899 6.56966 4.60899 8.10286C4.60899 9.63902 5.69896 11.1257 5.90063 11.4008C5.90619 11.4084 5.91108 11.415 5.91524 11.4207C5.92738 11.4367 5.95025 11.4698 5.98354 11.518C6.39532 12.1143 8.40101 15.0189 11.3919 16.258C13.9191 17.3057 14.6699 17.2081 15.2446 17.0857Z" fill="white"/>
  </svg>
</template>

<script>
export default {
name: "svgWs"
}
</script>

<style scoped lang="sass">
  svg
    margin-right: 10px
</style>