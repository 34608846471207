<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 6.66669C9.57174 6.66669 8.6815 7.01788 8.02513 7.643C7.36875 8.26812 7 9.11597 7 10C7 10.8841 7.36875 11.7319 8.02513 12.357C8.6815 12.9822 9.57174 13.3334 10.5 13.3334C11.4283 13.3334 12.3185 12.9822 12.9749 12.357C13.6313 11.7319 14 10.8841 14 10C14 9.11597 13.6313 8.26812 12.9749 7.643C12.3185 7.01788 11.4283 6.66669 10.5 6.66669Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.3 0C4.62914 0 3.02671 0.632141 1.84523 1.75736C0.663748 2.88258 0 4.4087 0 6L0 14C0 15.5913 0.663748 17.1174 1.84523 18.2426C3.02671 19.3679 4.62914 20 6.3 20H14.7C16.3709 20 17.9733 19.3679 19.1548 18.2426C20.3363 17.1174 21 15.5913 21 14V6C21 4.4087 20.3363 2.88258 19.1548 1.75736C17.9733 0.632141 16.3709 0 14.7 0L6.3 0ZM5.6 10C5.6 8.76232 6.11625 7.57534 7.03518 6.70017C7.95411 5.825 9.20044 5.33333 10.5 5.33333C11.7996 5.33333 13.0459 5.825 13.9648 6.70017C14.8838 7.57534 15.4 8.76232 15.4 10C15.4 11.2377 14.8838 12.4247 13.9648 13.2998C13.0459 14.175 11.7996 14.6667 10.5 14.6667C9.20044 14.6667 7.95411 14.175 7.03518 13.2998C6.11625 12.4247 5.6 11.2377 5.6 10ZM15.4 5.33333H16.8V4H15.4V5.33333Z" fill="white"/>
  </svg>
</template>

<script>
export default {
name: "inst"
}
</script>

<style scoped>

</style>