<template>
  <div id="app">
    <div class="pageContainer">
      <main-menu v-if="width > 750"/>
      <main-menu-mobile v-else/>
      <div class="imageCont">
        <div class="imageBlack">
          <transition name="opacity" mode="out-in">
            <router-view/>
          </transition>
        </div>
      </div>
      <contact-line/>
    </div>

  </div>
</template>

<script>
import MainMenu from "@/components/mainMenu";
import ContactLine from "@/components/contactLine";
import MainMenuMobile from "@/components/mainMenuMobile";
export default {
  components: {MainMenuMobile, ContactLine, MainMenu},
  data() {
    return {
      width: 0,
    }
  },
  methods: {
    resize(){
      this.width = window.innerWidth;
      window.addEventListener('resize', ()=>{
        this.width = window.innerWidth
      })
    }
  },
  created() {
    this.resize()
  }
}
</script>

<style lang="sass">
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap')
  =r($width)
    @media only screen and (max-width: $width+ "px")
      @content
  html
    background-color: #090909
  *
    box-sizing: border-box
  body
    font-family: 'Montserrat', sans-serif
    margin: 0
    padding: 0
    color: #fff
  .pageContainer
    display: flex
  .opacity-enter-active
    animation: slideIn 0.2s

  .opacity-leave-active
    animation: slideOut 0.2s
  .imageCont
    margin-left: 450px
    overflow-y: auto
    width: 100%
    background: #151515
    background-image: url(assets/Vector.svg)
    display: flex
    +r(1500)
      margin-left: 250px
    +r(750)
      margin-left: 0

  .imageBlack
    width: 100%
    min-height: 100vh
    position: relative
    background: radial-gradient(circle at center, rgba(0,0,0,0.5) 0%, rgba(0,0,0,1) 100%)
    display: flex
  @keyframes slideIn
    from
      opacity: 0
    to
      opacity: 1

  @keyframes slideOut
    from
      opacity: 1
    to
      opacity: 0


</style>